import React, { useContext } from "react";
import invariant from "tiny-invariant";
import type { TableSortState } from "~/components/numbered-table";
import type { CurrentAccessUserQuery } from "~/types/api";

export const NumberedTableContext = React.createContext<
  | {
      onSort?: (value: string, shift?: boolean) => void;
      sort?: TableSortState;
    }
  | undefined
>(undefined);

export const CurrentUserContext = React.createContext<
  CurrentAccessUserQuery["currentAccessUser"] | null
>(null);

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  invariant(context, "CurrentUser is required");
  return context;
};

export const useOptionalUser = () => {
  const context = useContext(CurrentUserContext);
  return context;
};
